import Download from "components/download"
import Layout from "components/layout"
import PropTypes from "prop-types"
import React from "react"
import SEO from "components/seo"
import { graphql } from "gatsby"

export default function Indir({ data }) {
  return (
    <Layout>
      <SEO
        title="Kütüphane Programı - İndir"
        description="Tusunami Kütüphane Programının en son kararlı sürümünü hemen indirin"
        pathname="https://www.kodvizit.com/kutuphane-programi/indir"
        datePublished="2017-02-09T22:22:55+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
        breadcrumbList={[
          {
            path: "https://www.kodvizit.com/kutuphane-programi",
            name: "Kütüphane Programı",
          },
        ]}
      />
      <Download
        headerText="Tusunami Kütüphane Programı’nı İndirdiğiniz İçin Teşekkürler!"
        icon={data.tktIcon.childImageSharp.fixed}
        downloadHref="/indir/tktguncel.exe"
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    tktIcon: file(relativePath: { eq: "icon/tkticon128.png" }) {
      childImageSharp {
        fixed(width: 128, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

Indir.propTypes = {
  data: PropTypes.object,
}
